/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

.full-screen-dialog .mat-dialog-container {
    padding: 0 !important;
    margin: 0 !important;
    max-width: 100vw !important;
    max-height: 100vh !important;
    height: 100vh !important;
    width: 100vw !important;
    border-radius: 0 !important;
    box-sizing: border-box !important; /* Make sure padding and border are included in the width and height */
}

.cdk-overlay-pane {
    padding: 0 !important;
    margin: 0 auto !important; /* Center the dialog if needed, but remove any margins that may cause gaps */
    max-width: 100vw !important;
    //width: 100vw !important;
    //left: initial !important; /* Reset any left positioning */
    //right: 0 !important; /* Position it to the right; adjust as necessary */
}

.sticky-header {
    position: sticky;
    padding: 15px;
    top: -25px; /* Adjust this value if you have a top bar or padding you want to account for */
    background: white; /* Or any background color to match your design */
    z-index: 100; /* Ensure it's above other content */
}

.nonsticky-header {
    position: static;
    padding: 15px;
    top: -25px; /* Adjust this value if you have a top bar or padding you want to account for */
    background: white; /* Or any background color to match your design */
    z-index: 100; /* Ensure it's above other content */
}

.scrollable-content {
    //padding-bottom: 20px;
    padding: 19px;
}

.close-button {
    position: absolute;
    top: 10px; /* Adjust as needed */
    right: 15px; /* Adjust as needed */
    background: transparent;
    border: none;
    color: black; /* Adjust color as needed */
    font-size: 24px; /* Adjust size as needed */
    cursor: pointer;
    z-index: 1001; /* Should be higher than the overlay z-index */
}

//::ng-deep .mat-autocomplete-panel {
//    width: 100% !important; /* Match the width to the input field */
//}
::ng-deep .mat-form-field {
    position: static;
}

